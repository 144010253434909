import React from 'react';

import type { ExtensionParams } from '@atlaskit/editor-common/extensions';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { RENDERER, getExperienceName } from '@confluence/macro-tracker';

import { CustomSitesRenderedExtension } from '../../shared-components/CustomSitesRenderedExtension';
import type { LinkCardsParameters } from '../linkCardsTypes';
import { LinkCardsContent } from '../LinkCardsContent';

type LinkCardsContainerProps = {
	extensionNode: ExtensionParams<LinkCardsParameters>;
	contentId: string;
};

export const LinkCards = ({ extensionNode, contentId }: LinkCardsContainerProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { parameters } = extensionNode;
	if (!parameters) {
		return null;
	}

	const experienceName = getExperienceName(RENDERER, extensionNode);

	return (
		<CustomSitesRenderedExtension extensionNode={extensionNode} contentId={contentId}>
			<LinkCardsContent
				parameters={parameters}
				isInViewMode
				contentId={contentId}
				experienceName={experienceName}
				createAnalyticsEvent={createAnalyticsEvent}
			/>
		</CustomSitesRenderedExtension>
	);
};
